.card-box {
    background-color: #FFF;
    width: 33.333333%;
    width: calc(33.333333% - 25px);
    padding: 24px;
    border-radius: 8px;
    border-top: 10px solid #eb008b;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.img img {
    width: 216px;
    height: 216px;
}

@media (max-width:1024px) and (min-width:768px) {
    .card-box {
        width: calc(50% - 25px);
    }
}

@media (max-width:767px) {
    .card-box {
        width: 100%;
    }
}