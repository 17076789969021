.container {
    padding: 40px 150px;
    background-color: rgb(247, 248, 250);
    min-height: 100vh;
}

@media (max-width:920px) {
    .container {
        padding: 40px 25px;
    }
}