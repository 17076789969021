* {
    box-sizing: border-box
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

.avatar-group .MuiAvatarGroup-avatar {
    width: 30px;
    height: 30px;
    font-size: 13px;
}

.nav-add-button .MuiSpeedDial-actions {
    position: absolute;
    top: 35px
}

.nav-add-button .css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
    width: 45px;
    height: 45px;
}

.project-form .MuiInputBase-root.MuiInput-root::before {
    border-color: #FFF !important;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }


  /*fullcalender*/
  .fc thead th{
    background: #0C1A3E;
    color: #FFF;
  }

  .fc .fc-button-group{
    gap: 7px
  }

  .fc .fc-button-primary,
  .fc .fc-button-group button{
    background-color: #0C1A3E;
  }

  .fc .fc-button-group button{
    border-radius: .25rem !important;
  }

  .fc thead th > div a {
    padding: 10px !important;
  }

  .fc tbody td {
    background-color: #FFF;
  }

  .fc .fc-daygrid-day.fc-day-today{
    background-color:#EFF3FF;
  }

  .fc-h-event{
    background-color: transparent;
    border: none
  }

  .recharts-legend-item{
    font-size: 12px;
  }

  /*send message*/
  .send-message-container{
    width: 80%;
    margin: auto;
    height: calc(30% - 70px);
    position: relative;
  }

  .send-message-container form{
    height: 100%;
  }

  .send-message-container textarea{
    width: 100%;
    height: 100%;
    border: 1px solid !important;
    outline: none !important;
    background-color: #F1F1F1;
    border-radius: 12px;
    padding: 16px;
    font-size: 14px;
  }

  .emoji-container{
    position: absolute;
    bottom: 0;
    right: 0;
  }

  @media(max-width:767px){
    .emoji-container{
      right: unset;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .chat-msg a{
    /* color: #FFF; */
    text-decoration: underline !important;
  }

  .MuiPopover-root,
  .MuiDialog-root,
  .MuiModal-root{
    z-index: 99999 !important;
  }

  @media(max-width:767px){
    .auth-form-layout .MuiFormControl-root{
      width: 100%;
    }
  }