.btn {
    width: 100%;
    height: 50px;
    font-size: 14px;
    font-weight: 500;
    transition: all 200ms ease 0s;
    border-radius: 10px;
}

.outline {
    color: #1f2f4c;
    border: 1px solid #1f2f4c;
}

.btn:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px 0;
}

.colored {
    color: #fff;
    background-color: #eb008b;
}

.disabled {
    color: #8f93a7;
    background-color: #e4e4ed;
}

.colored:hover {
    opacity: 0.8;
}