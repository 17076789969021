.input>div {
    border: none !important;
    background-color: transparent !important;
}

.input label {
    font-size: 12px;
    color: rgb(121, 126, 150);
    line-height: 1.2;
    margin-bottom: 15px;
}

.input input {
    padding: 0 12px;
    height: 48px;
    border: 1px solid rgb(195, 200, 217) !important;
    outline: 2px solid transparent !important;
    border-radius: 10px;
    font-size: 14px;
    color: rgb(32, 40, 80);
}

.input input:hover,
.input input:focus {
    outline: 2px solid rgb(56, 74, 143) !important;
    border: 1px solid transparent !important;


}

.input fieldset {
    border: none !important;
    outline: none !important;
}

.default>div input {
    background-color: rgb(248, 248, 250) !important;
}

.white>div input {
    background-color: #fff !important;
}

.default>div input:focus {
    background-color: #fff !important;
}

.error-input input,
.error-input input:hover,
.error-input input:focus {
    border-color: transparent;
    outline-color: #d32f2f !important;
}