/* * {
    font-family: 'Courier New', Courier, monospace !important;
} */

@font-face {
    font-family: 'Circular-Std';
    src: url('/public/fonts/CircularStd-Book.otf'),
        url('/public/fonts/CircularStd-Medium.otf'),
        url("/public/fonts/CircularStd-Bold.otf");
}

* {
    font-family: "Circular-Std" !important;
}

body {
    overflow-y: auto !important;
}

.MuiFormHelperText-root {
    margin-top: 5px !important;
    margin-left: 0 !important;
}